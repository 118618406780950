import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
import * as Yup from "yup";
import moment from "moment";

import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import Document from "../CustomerDetails/Document";
import {
  ConditionFieldOptions,
  ERRORS_MESSAGE,
  FILE_AWAITING_MESSAGE,
  SUCCESS_MESSAGE,
  creditCardModalInfo,
  fieldRequiredMessage,
  typeOfSaleList,
} from "../../constants";
import assetTypes from "../../assetTypes.json";
import consumerAssetTypes from "../../utils/consumerAssetTypes";
import generateArrayOfYears from "../../utils/generateArrayOfYears";
import regex from "../../utils/regex";
import checkAlphabetRestrict from "../../utils/checkAlphabetRestrict";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import { userSelector } from "../../store/slices/userSlice";
import AutoCompleteLoader from "../Application/AutoCompleteLoader";
import {
  assetSelector,
  getDetailsSpecificationAll,
  getListOfMakes,
  getListOfMakesYearFromTo,
  getListOfModels,
  getListOfModelsYearFromTo,
  getListOfNVICForAdvancedSearch,
  getListOfSeries,
  getListOfVariants,
  getListOfVariantsYearFromTo,
  getLookupBlueflag,
  getPPSRSearchBlueflag,
  loanDetailsSelector,
  // saveAsset,
  setValidationRequireFieldError,
  updateAgeOfAsset,
  updateAsset,
  updateGlassAsset,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { isValidInput } from "../Utils/helperFunction";
import { HtmlTooltip } from "../ProductSelector/Product";

export const glassesAssetsSearchableList = [
  "Motor Vehicle (<4.5 tonnes)",
  "Electric Motor Vehicle (<4.5 tonnes)",
  "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  "Commercial (Heavy) Vehicles (>12 Tonnes)",
  "Mini Buses & Route Buses (<25 seats)",
  "Motorbikes, ATVs",
  "Trailers and Caravans",
  "Classic car",
  "Motor Vehicle",
  "Electric Motor Vehicle",
  "Luxury Vehicle",
  "Motorbike",
  "Caravan",
];

// const assetTypeCodeList = {
//   "Motor Vehicle (<4.5 tonnes)": "APAS",
//   "Electric Motor Vehicle (<4.5 tonnes)": "APAS",
//   "Classic car": "APAS",
//   "Commercial (Small to Medium) Vehicles (<12 Tonnes)": "ALCV",
//   "Commercial (Heavy) Vehicles (>12 Tonnes)": "AHCV",
//   "Motorbikes, ATVs": "B",
//   "Trailers and Caravans": "C",
//   // MBOA: BOAT
//   // MMOT: MOTOR
//   // MPWC: PWC
//   // "Trailers and Caravans": "MTRA",
// };

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const Asset = React.memo(({ applicationType, fieldErrors = {}, setFieldErrors, applicationId }) => {
  const dispatch = useDispatch();

  const userDetails = useSelector(userSelector);
  const { validationRequireFieldError } = useSelector(userDetailsSelector);
  const { assetErrors } = validationRequireFieldError;
  const { enqueueSnackbar } = useSnackbar();
  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const { term } = useSelector(loanDetailsSelector);

  const assetTypesList = applicationType === "commercial" ? assetTypes.assetTypes : consumerAssetTypes;

  const {
    _id,
    assetValue,
    ageOfAsset = "",
    assetType = "",
    make = "",
    model = "",
    typeOfSale = "",
    condition = "",
    listOfMakes = [],
    listOfModels = [],
    listOfVariants = [],
    specifications,
    NVICList = [],
    NVICData,
    modelLoader,
    makeLoader,
    variantLoader,
    searchVehicleLoader,
    searchPPSRLoader,
    NVICLoader,
    glassesMake = {},
    glassesModel = {
      label: "",
      code: "",
    },
    nvicLoaderData,
    glassesNVIC = { label: "" },
    glassesVariant,
    registrationState,
    registrationNumber,
    vin,
    blueFlag,
    isManualAssets,
  } = asset;

  const { deposit = 0, tradeIn = 0, payout = 0 } = loanDetails;
  const [showSearchAssetView, setShowSearchAssetView] = useState(false);
  // const [toggleManualAsset, setToggleManualAsset] = useState(false);
  // const [assetTypeCode, setAssetTypeCode] = useState(false);

  // const [fieldErrors, setFieldErrors] = useState({
  //   assetValue: "",
  //   condition: "",
  //   make: "",
  //   model: "",
  //   glassesMake: "",
  //   glassesModel: "",
  //   glassesVariant: "",
  //   glassesNVIC: "",
  //   glassesData: "",
  // });

  const [allValues, setAllValue] = useState({
    condition: condition || "",
    glassesData: {},
    specifications: specifications?.YearCreate ? specifications : NVICData || null,
    assetValue: assetValue || "",
    assetModelTypeCode: "",
    assetTypeValue: assetType || "",
    ageOfAssetValue: ageOfAsset || "",
    make: make || "",
    model: model || "",
    glassesMake: glassesMake,
    glassesModel: glassesModel,
    glassesVariant: glassesVariant || { label: "", value: "" },
    glassesNVIC: glassesNVIC,
    typeOfSaleValue: typeOfSale || "",
    registrationState: registrationState || "",
    registrationNumber: registrationNumber || null,
    vin: vin || null,
    searchQuery: registrationNumber || vin || "",
    isManualAssets: isManualAssets || false,
    // specifications: specifications || {},
  });

  const allValuesRef = useRef(allValues);

  const disablePPSRButton =
    !userDetails?.is_service_enable ||
    !allValues.searchQuery ||
    (allValues?.registrationNumber && !allValues?.registrationState);

  const disableSearchVehicleButton =
    !userDetails?.is_service_enable || searchVehicleLoader || (!allValues?.vin && !allValues?.registrationNumber);

  let blueflagRequirementMessage = "";

  if (!allValues.searchQuery) {
    blueflagRequirementMessage = "Requires VIN or Registration Plate and State";
  } else if (allValues.registrationNumber && !allValues.registrationState) {
    blueflagRequirementMessage = "Requires state";
  }

  const validationSchema = Yup.object({
    assetValue: Yup.string()
      .required(fieldRequiredMessage.assetValue)
      .matches(regex.allowOnlyNumber, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .min(3, "Minimum of 3 digits")
      .max(10, "Maximum of 7 digits"),
    model: Yup.string()
      .optional()
      .matches(regex.alphaNumericAndSpecificChar, "Only letters, numbers and . ! / ( ) - + characters allowed.")
      .max(50, "Maximum of 50 characters"),
    make: Yup.string()
      .optional()
      .matches(regex.alphaNumericAndSpecificChar, "Only letters, numbers and . ! / ( ) - + characters allowed.")
      .max(50, "Maximum of 50 characters"),
  });

  // console.log("Asset Val", allValues);

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        dispatch(
          setValidationRequireFieldError({
            ...validationRequireFieldError,
            assetErrors: { ...assetErrors, [fieldName]: "" },
          }),
        );
      } catch (error) {
        dispatch(
          setValidationRequireFieldError({
            ...validationRequireFieldError,
            assetErrors: { ...assetErrors, [fieldName]: error.message },
          }),
        );
      }
    }, 300),
    [validationRequireFieldError, assetErrors],
  );

  // const validateField = async (fieldName, value) => {
  //   try {
  //     await validationSchema.validateAt(fieldName, { [fieldName]: value });
  //     // setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //     dispatch(setValidationRequireFieldError({ ...validationRequireFieldError, assetErrors: { ...assetErrors, [fieldName]: "" } }))
  //   } catch (error) {
  //     // setFieldErrors((prevErrors) => ({
  //     //   ...prevErrors,
  //     //   [fieldName]: error.message,
  //     // }));
  //     dispatch(setValidationRequireFieldError({ ...validationRequireFieldError, assetErrors: { ...assetErrors, [fieldName]: error.message } }))
  //   }
  // };

  const handle = {
    cleanAssetList: (name) => {
      switch (name) {
        case "ageOfAssetValue":
          const resetAgeOfAssetState = {
            listOfMakes: [],
            listOfModels: [],
            listOfVariants: [],
            NVICList: [],
            glassesData: {},
            specifications: null,
            glassesMake: null,
            glassesModel: null,
            glassesVariant: null,
            glassesNVIC: null,
          };

          dispatch(
            updateAsset({
              _id,
              ...resetAgeOfAssetState,
            }),
          );
          dispatch(
            updateGlassAsset({
              ...asset,
              ...resetAgeOfAssetState,
            }),
          );
          return;

        case "glassesMake":
          const resetGlassesMakeState = {
            glassesData: {},
            specifications: null,
            glassesModel: null,
            glassesVariant: null,
            glassesNVIC: null,
            listOfModels: [],
            listOfVariants: [],
            NVICList: [],
          };

          dispatch(
            updateGlassAsset({
              ...asset,
              ...resetGlassesMakeState,
            }),
          );
          dispatch(
            updateAsset({
              _id,
              ...resetGlassesMakeState,
            }),
          );
          return;

        case "glassesModel":
          dispatch(
            updateAsset({
              _id,
              specifications: null,
              glassesVariant: null,
              glassesNVIC: { label: "", value: "" },
              listOfVariants: [],
              NVICList: [],
            }),
          );
          dispatch(
            updateGlassAsset({
              ...asset,
              glassesData: {},
              specifications: null,
              glassesVariant: null,
              glassesNVIC: { label: "", value: "" },
              listOfVariants: [],
              NVICList: [],
            }),
          );
          return;

        case "glassesVariant":
          dispatch(
            updateAsset({
              _id,
              specifications: null,
              glassesNVIC: { label: "", value: "" },
              NVICList: [],
            }),
          );
          dispatch(
            updateGlassAsset({
              ...asset,
              glassesData: {},
              specifications: null,
              glassesNVIC: { label: "", value: "" },
              NVICList: [],
            }),
          );
          return;

        default:
          return;
      }
    },
    updateAssetModalCode: (e) => {
      const findModalObject = assetTypesList?.find((el) => el.label === e);
      // console.log({ findModalObject })
      const updateValue = {
        ...allValues,
        assetTypeValue: e || findModalObject?.label,
        assetModelTypeCode: findModalObject?.modalTypeCode,
      };
      setAllValue(updateValue);

      return updateValue;
    },

    updateAsset: async (value) => {
      if (_id) {
        const keys = Object.keys(value);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (asset[key] !== value[key]) {
            // dispatch(saveAsset(value));
            dispatch(updateAsset({ _id, ...value }));
            return;
          }
        }
      }
    },
    ageOfAsset: (newValue) => {
      const termInYears = term / 12;
      const ageOfAssetAtEnd = moment().add(termInYears, "years").diff(moment(newValue, "YYYY"), "years");
      // dispatch(updateAsset({ _id, ageOfAsset: newValue, ageOfAssetAtEnd }));
      return ageOfAssetAtEnd
    },
    // onChangeField: async (e, name) => {
    onChangeField: (e, name) => {
      let isValid = true;
      let oldVal = asset[name];
      if (oldVal === e) {
        return;
      }

      if (name === "assetTypeValue") {
        // handle.ageOfAsset("");

        // handle.blurFn("glassesMake", { label: "", value: "" });
        // handle.blurFn("glassesModel", "");
        // handle.blurFn("glassesVariant", { label: "", value: "" });
        // handle.blurFn("glassesNVIC", { label: "", value: "" });

        // setAllValue({
        //   ...allValues,
        //   [name]: e,
        //   // ageOfAsset: "",
        //   glassesMake: { label: "", value: "" },
        //   glassesModel: "",
        //   glassesVariant: { label: "", value: "" },
        //   glassesNVIC: { label: "", value: "" },
        // });

        const updateValue = {
          condition: typeOfSale && typeOfSale === "Private sale" ? "Used" : (condition || ""),
          specifications: null,
          // ageOfAsset: "",
          glassesMake: null,
          glassesModel: null,
          glassesVariant: null,
          glassesNVIC: null,
        };

        handle.assetFieldsView(e);

        dispatch(
          updateGlassAsset({
            ...asset,
            ...updateValue,
          }),
        );

        setAllValue({
          ...allValues,
          ...updateValue,
          [name]: e,
        });

        return dispatch(
          updateAsset({
            ...updateValue,
            assetType: e,
            _id,
          }),
        );
      }

      if (name === "model" || name === "make") {
        isValid = regex.alphaNumericAndSpecificChar.test(e) && e?.length <= 50;
      } else if (name === "assetValue") {
        isValid = isValidInput(e);
        console.log("isValid", isValid);
      }

      if (name === "typeOfSaleValue") {
        // if (e === "Private sale") {
        //   handle.ageOfAsset("");

        //   const updateValue = {
        //     glassesData: {},
        //     specifications: null,
        //     ageOfAssetValue: "",
        //     [name]: e,
        //     typeOfSale: e,
        //     condition: "Used",
        //     glassesMake: null,
        //     glassesModel: null,
        //     glassesVariant: null,
        //     glassesNVIC: null,
        //   };

        //   setAllValue({
        //     ...allValues,
        //     ...updateValue,
        //   });

        //   dispatch(
        //     updateGlassAsset({
        //       ...asset,
        //       ...updateValue,
        //       ageOfAsset: "",
        //     }),
        //   );

        //   return dispatch(
        //     updateAsset({
        //       _id,
        //       ...updateValue,
        //     }),
        //   );
        // }

        const updateValue = {
          // [name]: e,
          typeOfSale: e,
          ...(e === "Private sale" && { condition: "Used" }),
        };

        setAllValue({
          ...allValues,
          ...updateValue,
        });

        dispatch(
          updateGlassAsset({
            ...asset,
            ...updateValue,
          }),
        );

        return dispatch(
          updateAsset({
            ...updateValue,
            _id,
          }),
        );
      }

      if (name === "condition") {
        setAllValue({
          ...allValues,
          [name]: e,
          // ageOfAssetValue: "",
          // glassesMake: { label: "", value: "" },
          // glassesModel: "",
          // glassesVariant: { label: "", value: "" },
          // glassesNVIC: { label: "", value: "" },
        });

        return dispatch(
          updateAsset({
            [name]: e,
            _id,
          }),
        );
      }

      if (name === "ageOfAssetValue") {
        dispatch(updateAgeOfAsset(e));
        // handle.ageOfAsset(e);

        const updateValue = {
          // ageOfAsset: e,
          // ageOfAssetAtEnd: handle.ageOfAsset(e),
          glassesData: {},
          specifications: null,
          glassesMake: null,
          glassesModel: null,
          glassesVariant: null,
          glassesNVIC: null,
          listOfMakes: [],
          listOfModels: [],
          listOfVariants: [],
          NVICList: [],
        };

        setAllValue({
          ...allValues,
          ...updateValue,
          [name]: e,
        });

        dispatch(
          updateGlassAsset({
            ...asset,
            ...updateValue,
            ageOfAsset: e,
          }),
        );
        return dispatch(
          updateAsset({
            // [name]: e,
            _id,
            ageOfAsset: e,
            ageOfAssetAtEnd: handle.ageOfAsset(e),
            ...updateValue,
          }),
        );
      }

      if (name === "glassesMake") {
        const updateValue = {
          [name]: e,
          glassesData: {},
          specifications: null,
          glassesModel: null,
          glassesVariant: null,
          glassesNVIC: null,
          // new added
          listOfModels: [],
          listOfVariants: [],
          NVICList: [],
        };

        setAllValue({
          ...allValues,
          ...updateValue,
        });

        dispatch(
          updateGlassAsset({
            ...asset,
            ...updateValue,
          }),
        );

        return dispatch(
          updateAsset({
            _id,
            ...updateValue,
          }),
        );
      }

      if (name === "glassesModel") {
        setAllValue({
          ...allValues,
          [name]: e,
          glassesData: {},
          specifications: null,
          glassesVariant: { label: "", value: "" },
          glassesNVIC: { label: "", value: "" },
        });

        dispatch(
          updateGlassAsset({
            ...asset,
            [name]: e,
            glassesData: {},
            specifications: null,
            glassesVariant: null,
            glassesNVIC: { label: "", value: "" },
            listOfVariants: [],
            NVICList: [],
          }),
        );

        return dispatch(
          updateAsset({
            [name]: e,
            specifications: null,
            glassesVariant: null,
            glassesNVIC: { label: "", value: "" },
            listOfVariants: [],
            NVICList: [],
            _id,
          }),
        );
      }

      if (name === "glassesVariant") {
        setAllValue({
          ...allValues,
          [name]: e === null ? { label: "", value: "" } : e,
          glassesData: {},
          specifications: null,
          glassesNVIC: { label: "", value: "" },
        });

        dispatch(
          updateGlassAsset({
            ...asset,
            [name]: e,
            glassesData: {},
            specifications: null,
            glassesNVIC: { label: "", value: "" },
            NVICList: [],
          }),
        );

        return dispatch(
          updateAsset({
            [name]: e,
            specifications: null,
            glassesNVIC: { label: "", value: "" },
            NVICList: [],
            _id,
          }),
        );
      }

      if (!isValid) {
        // await validateField(name, e);
        debouncedValidation(name, e);
        return;
      } else {
        setAllValue({ ...allValues, [name]: e });
        // await validateField(name, e);
        debouncedValidation(name, e);
      }
    },
    blurFn: async (fieldName, value) => {
      try {
        // await validateField(fieldName, value);
        // console.log("await==>>")

        if (fieldErrors[fieldName] === "" || fieldName) {
          handle.updateAsset({ [fieldName]: value });
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    assetFieldsView: (assetTypeValue) => {
      const dataSetting = handle.updateAssetModalCode(assetTypeValue);

      if (glassesAssetsSearchableList.includes(assetTypeValue)) {
        // if ((!listOfModels && glassesMake?.value) || glassesMake?.value) {
        //   handle.getListOfModels(
        //     glassesMake?.value,
        //     dataSetting.assetModelTypeCode
        //   );
        // }
        // if ((!listOfVariants && glassesModel?.label) || glassesModel?.label) {
        //   handle.getListOfVariants(
        //     glassesModel?.label,
        //     dataSetting.assetModelTypeCode
        //   );
        // }
        // if ((!NVICList && glassesVariant?.label) || glassesVariant?.label) {
        //   handle.getNVIC(glassesVariant?.label, dataSetting.assetModelTypeCode);
        // }
        // if ((!NVICData && glassesNVIC?.NVIC) || glassesNVIC?.NVIC) {
        //   handle.getDetailsSpecificationAll(
        //     glassesNVIC?.NVIC,
        //     dataSetting.assetModelTypeCode
        //   );
        // }
        // if ((!listOfMakes && ageOfAsset) || ageOfAsset) {
        setAllValue((prevAllValues) => {
          const newAllValues = {
            ...prevAllValues,
            condition: typeOfSale === "Private sale" ? "Used" : "",
            assetTypeValue,
            ageOfAssetValue: "",
            glassesData: {},
            glassesMake: { label: "", value: "" },
            glassesModel: "",
            glassesVariant: { label: "", value: "" },
            glassesNVIC: { label: "", value: "" },
            assetModelTypeCode: dataSetting.assetModelTypeCode,
          }

          allValuesRef.current = newAllValues;
          return newAllValues;
        });
        handle.getListOfMakes(ageOfAsset, dataSetting.assetModelTypeCode);
        // }

        return setShowSearchAssetView("search");
      }

      setShowSearchAssetView("manual");
    },
    getListOfMakes: async (yearValue, assetCode) => {
      if (yearValue || ageOfAsset) {
        await dispatch(
          getListOfMakes({
            assetTypeCode: assetCode || allValues?.assetModelTypeCode || "APAS",
            year: yearValue || ageOfAsset,
          }),
        ).unwrap();
      } else {
        // console.log({ assetTypeCode })
        await dispatch(
          getListOfMakesYearFromTo({
            assetTypeCode: assetCode || allValues?.assetModelTypeCode || "APAS",
            from: "1961",
            to: "2023",
          }),
        ).unwrap();
      }
    },
    getListOfModels: async (makeCode, assetCode) => {
      if (allValues?.ageOfAssetValue) {
        await dispatch(
          getListOfModels({
            makeCode,
            assetTypeCode: assetCode || allValuesRef?.current?.assetModelTypeCode || "APAS",
            year: allValues?.ageOfAssetValue,
          }),
        ).unwrap();
      } else {
        // console.log({ assetTypeCode })
        await dispatch(
          getListOfModelsYearFromTo({
            makeCode,
            assetTypeCode: assetCode || allValuesRef?.current?.assetModelTypeCode || "APAS",
            from: "1961",
            to: "2023",
          }),
        ).unwrap();
      }
    },
    getListOfVariants: async (modelCode, assetCode) => {
      if (allValues?.ageOfAssetValue) {
        await dispatch(
          getListOfVariants({
            assetTypeCode: assetCode || allValues?.assetModelTypeCode || "APAS",
            year: allValues?.ageOfAssetValue,
            makeCode: allValues?.glassesMake?.value || glassesMake?.value,
            modelCode: modelCode || allValues?.glassesModel?.value || glassesModel?.value,
          }),
        ).unwrap();
      } else {
        dispatch(
          getListOfVariantsYearFromTo({
            assetTypeCode: assetCode || allValues?.assetModelTypeCode || "APAS",
            from: "1961",
            to: "2023",
            makeCode: allValues?.glassesMake?.value || glassesMake?.value,
            modelCode: modelCode || allValues?.glassesModel?.value || glassesModel?.value,
          }),
        );
      }
    },
    //getSeries Dispatch function
    getSeries: (variant) => {
      dispatch(
        getListOfSeries({
          assetTypeCode: allValues?.assetModelTypeCode,
          yearCreate: ageOfAsset,
          makeCode: allValues?.glassesMake?.value,
          modelCode: allValues?.glassesModel?.value,
          variant: variant,
        }),
      );
    },
    getNVIC: async (variant, assetCode) => {
      await dispatch(
        getListOfNVICForAdvancedSearch({
          assetTypeCode: assetCode || allValues?.assetModelTypeCode || "APAS",
          yearCreate: allValues?.ageOfAssetValue,
          makeCode: allValues?.glassesMake?.value,
          modelCode: allValues?.glassesModel?.value,
          variant: allValues?.glassesVariant.value,
          // there not field about getSeries that why we use SeriesCode like this
          // SeriesCode: listOfSeries[0]?.code,`
        }),
      ).unwrap();
    },
    getDetailsSpecificationAll: async (NVIC) => {
      await dispatch(
        getDetailsSpecificationAll({
          assetTypeCode: allValues?.assetModelTypeCode || "A",
          NVIC,
        }),
      ).unwrap();
      // handle.updateAsset({ specifications: NVICData });
    },
    getLookupBlueflag: (query) => {
      if (query.vin)
        return dispatch(
          getLookupBlueflag(`vin=${query?.vin?.replace(/\s+/g, "")}&applicationId=${applicationId}`),
        ).then((res) => {
          if (res?.payload?.data?.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
              variant: "success",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });

      dispatch(
        getLookupBlueflag(
          `plate=${query?.registrationNumber?.replace(/\s+/g, "")}&state=${query.registrationState
          }&applicationId=${applicationId}`,
        ),
      ).then((res) => {
        if (res?.payload?.data?.data) {
          enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    getPPSRSearchBlueflag: (query) => {
      if (query.vin)
        return dispatch(
          getPPSRSearchBlueflag(`vin=${query?.vin?.replace(/\s+/g, "")}&applicationId=${applicationId}`),
        ).then((res) => {
          if (res?.payload?.data?.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
              variant: "success",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        });

      dispatch(
        getPPSRSearchBlueflag(
          `plate=${query?.registrationNumber?.replace(/\s+/g, "")}&state=${query.registrationState
          }&applicationId=${applicationId}`,
        ),
      ).then((res) => {
        if (res?.payload?.data?.data) {
          enqueueSnackbar(SUCCESS_MESSAGE.fetchMsg, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
  };

  useEffect(() => {
    if (NVICData?.YearCreate) {
      // handle.onChangeField(glassesData?.YearCreate, "ageOfAssetValue");
      // handle.ageOfAsset(glassesData?.YearCreate);
      const specData = {};
      specData.ageOfAssetValue = NVICData?.YearCreate;
      specData.ageOfAsset = NVICData?.YearCreate;
      specData.ageOfAssetAtEnd = handle.ageOfAsset(NVICData?.YearCreate);
      specData.glassesMake = {
        label: NVICData?.ManufacturerName,
        value: allValues?.glassesMake?.value,
      };
      specData.glassesModel = {
        label: NVICData?.FamilyName,
        value: allValues?.glassesModel?.value,
      };
      specData.glassesVariant = {
        label: NVICData?.VariantName || allValues?.glassesVariant?.label,
      };
      specData.glassesNVIC = NVICData?.ModelName;
      specData.specifications = NVICData;

      setAllValue({ ...allValues, ...specData });
      handle.updateAsset({ ...allValues, ...specData });
    }
  }, [NVICData]);

  useEffect(() => {
    if (blueFlag?.nvic) handle.getDetailsSpecificationAll(blueFlag?.nvic);
  }, [blueFlag]);

  useEffect(() => {
    if (glassesAssetsSearchableList.includes(allValues.assetTypeValue)) {
      return setShowSearchAssetView("search");
    }
    setShowSearchAssetView("manual");
  }, []);

  useEffect(async () => {
    await loadSelectOptions();
  }, []);

  async function loadSelectOptions() {
    const dataSetting = handle.updateAssetModalCode(allValues.assetTypeValue);
    if (glassesAssetsSearchableList.includes(allValues.assetTypeValue)) {
      setAllValue({
        ...allValues,
        assetModelTypeCode: dataSetting.assetModelTypeCode,
      });
      // console.log("getting makes");
      await handle.getListOfMakes(allValues.ageOfAssetValue, dataSetting.assetModelTypeCode);
    }
    if ((!listOfModels && glassesMake?.value) || glassesMake?.value) {
      // console.log("getting models");
      await handle.getListOfModels(glassesMake?.value, allValues.assetModelTypeCode);
    }
    if ((!listOfVariants && glassesModel?.label) || glassesModel?.label) {
      // console.log("getting variants");
      await handle.getListOfVariants(glassesModel?.value, allValues.assetModelTypeCode);
    }
    if ((!NVICList && glassesVariant?.label) || glassesVariant?.label) {
      // console.log("getting nvic");
      await handle.getNVIC(glassesVariant?.label, allValues.assetModelTypeCode);
    }
    // if ((!NVICData && glassesNVIC?.NVIC) || glassesNVIC?.NVIC) {
    //   console.log("getting data")
    //   await handle.getDetailsSpecificationAll(
    //     glassesNVIC?.NVIC,
    //     allValues.assetModelTypeCode
    //   );
    // }
  }

  const handleToggleAssetDetails = () => {
    // handle.cleanAssetList("glassesMake");
    // handle.cleanAssetList("glassesModel");
    // handle.cleanAssetList("glassesVariant");


    // setToggleManualAsset(!toggleManualAsset);
    setAllValue({
      ...allValues,
      isManualAssets: !isManualAssets,
      // listOfMakes: [],
      listOfModels: [],
      listOfVariants: [],
      NVICList: [],
      glassesData: {},
      specifications: null,
      glassesMake: null,
      glassesModel: null,
      glassesVariant: null,
      glassesNVIC: null,
    });

    dispatch(
      updateGlassAsset({
        ...asset,
        isManualAssets: !isManualAssets,
        // listOfMakes: [],
        listOfModels: [],
        listOfVariants: [],
        NVICList: [],
        glassesData: {},
        specifications: null,
        glassesMake: null,
        glassesModel: null,
        glassesVariant: null,
        glassesNVIC: null,
      }),
    );

    return dispatch(
      updateAsset({
        _id,
        isManualAssets: !isManualAssets,
        // listOfMakes: [],
        listOfModels: [],
        listOfVariants: [],
        NVICList: [],
        glassesData: {},
        specifications: null,
        glassesMake: null,
        glassesModel: null,
        glassesVariant: null,
        glassesNVIC: null,
      }),
    );

  };

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Asset</Typography>
        </Stack>
        {nvicLoaderData ? (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "50px",
            }}
          >
            <CircularProgress size={40} />
          </Box>
        ) : (
          <React.Fragment>
            {allValues?.specifications && allValues?.specifications?.ClassCode && (
              <Stack mb="10px">
                <Typography style={{ marginBottom: "10px" }}>
                  <strong>LVR: </strong>
                  {/* <br></br> */}
                  {assetValue ? (
                    `${(
                      ((assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1) /
                        parseInt(allValues?.specifications?.Retail)) *
                      100 || 0
                    )?.toFixed(2)}%`
                  ) : (
                    <>
                      <br></br>
                      <Typography variant="caption">Add purchase price</Typography>
                    </>
                  )}
                </Typography>
                <Typography variant="caption">
                  <strong>Retail:</strong>
                  <br></br>
                  {parseInt(allValues?.specifications?.Retail || 0)?.toLocaleString("en-US", dollarStringOptions)}
                </Typography>
                <Typography variant="caption">
                  <strong>Private sale:</strong>
                  <br></br>
                  {parseInt(allValues?.specifications?.PrivateSale?.split("-")[0] || 0)?.toLocaleString(
                    "en-US",
                    dollarStringOptions,
                  )}{" "}
                  -{" "}
                  {parseInt(allValues?.specifications?.PrivateSale?.split("-")[1] || 0)?.toLocaleString(
                    "en-US",
                    dollarStringOptions,
                  )}
                </Typography>
                <Typography variant="caption">
                  <strong>Dealer retail:</strong>
                  <br></br>
                  {parseInt(allValues?.specifications?.DealerRetail?.split("-")[0] || 0)?.toLocaleString(
                    "en-US",
                    dollarStringOptions,
                  )}{" "}
                  -{" "}
                  {parseInt(allValues?.specifications?.DealerRetail?.split("-")[1] || 0)?.toLocaleString(
                    "en-US",
                    dollarStringOptions,
                  )}
                </Typography>
                <Typography variant="caption">
                  <strong>Dealer trade:</strong>
                  <br></br>
                  {parseInt(allValues?.specifications?.DealerTrade?.split("-")[0] || 0)?.toLocaleString(
                    "en-US",
                    dollarStringOptions,
                  )}{" "}
                  -{" "}
                  {parseInt(allValues?.specifications?.DealerTrade?.split("-")[1] || 0)?.toLocaleString(
                    "en-US",
                    dollarStringOptions,
                  )}
                </Typography>
                <Typography variant="caption">
                  <strong>RRP:</strong>
                  <br></br>
                  {parseInt(allValues?.specifications?.RRP || 0)?.toLocaleString("en-US", dollarStringOptions)}
                </Typography>
                <Typography variant="caption">
                  <strong>Average km:</strong>
                  <br></br>
                  {`${allValues?.specifications?.AverageKM}km`}
                </Typography>
              </Stack>
            )}

            {asset?.blueflagPPSR?.nevdisData && asset?.blueflagPPSR?.nevdisData?.verification_status === "Found" && (
              <Stack>
                <Typography variant="caption">
                  <strong>VIN:</strong>
                  <br></br>
                  {asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.vehicle_identifier?.identifier_value}
                </Typography>
                <Typography variant="caption">
                  <strong>Registration No:</strong>
                  <br></br>
                  {asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.vehicle_detail?.registration?.plate}
                </Typography>
                <Typography variant="caption">
                  <strong>Engine No:</strong>
                  <br></br>
                  {
                    asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.vehicle_detail?.vehicle_description
                      ?.engine_number
                  }
                </Typography>
                <Typography variant="caption">
                  <strong>Compliance Plate:</strong>
                  <br></br>
                  {
                    asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.vehicle_detail?.vehicle_description
                      ?.compliance_plate
                  }
                </Typography>
                <Typography variant="caption">
                  <strong>Stolen:</strong>
                  <br></br>
                  {asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.stolen.length > 0 ? "Yes" : "No"}
                </Typography>
                <Typography variant="caption">
                  <strong>Written Off:</strong>
                  <br></br>
                  {asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.written_off_details.length > 0 ? "Yes" : "No"}
                </Typography>
                <Typography variant="caption">
                  <strong>Safety Recalls:</strong>
                  <br></br>
                  {asset?.blueflagPPSR?.nevdisData?.nevdis_vehicles?.[0]?.safety_recalls.length > 0 ? "Yes" : "No"}
                </Typography>
              </Stack>
            )}
          </React.Fragment>
        )}
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={10}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
      >
        <Grid container spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <Grid item md={3} sm={3}>
            <TextField
              id={"assetValue"}
              fullWidth
              variant="filled"
              type="text"
              disabled={searchVehicleLoader}
              name="assetValue"
              label="Purchase price"
              size="small"
              value={allValues?.assetValue}
              error={assetErrors?.assetValue}
              helperText={assetErrors?.assetValue}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              onChange={(event) => handle.onChangeField(event?.target?.value, "assetValue")}
              onBlur={(e) => handle.blurFn("assetValue", currencyMaskedValue(e.target.value))}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              disabled={searchVehicleLoader}
              variant="filled"
              style={{ background: "#ffffff" }}
              select
              value={allValues?.assetTypeValue}
              label="Asset type"
              name="assetTypeValue"
              size="small"
              error={allValues?.assetTypeValue ? false : assetErrors?.assetType}
              helperText={allValues?.assetTypeValue ? false : assetErrors?.assetType}
              onChange={(event) => {
                handle.onChangeField(event.target.value, "assetTypeValue");
                // handle.updateAsset({ assetType: event.target.value });
              }}
            >
              {assetTypesList?.map((asset, i) => (
                <MenuItem value={asset.label} key={asset.label}>
                  {asset.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              disabled={searchVehicleLoader}
              variant="filled"
              style={{ background: "#ffffff" }}
              select
              value={typeOfSale || allValues?.typeOfSaleValue}
              name="typeOfSaleValue"
              label="Type of sale"
              size="small"
              error={typeOfSale || allValues?.typeOfSaleValue ? false : assetErrors?.typeOfSale}
              helperText={typeOfSale || allValues?.typeOfSaleValue ? false : assetErrors?.typeOfSale}
              onChange={(event) => {
                handle.onChangeField(event.target.value, "typeOfSaleValue");
                // handle.updateAsset({ typeOfSaleValue: event.target.value });
              }}
            >
              {Object.keys(typeOfSaleList)
                .map((item, i) => {
                  if (i === 0) return { label: typeOfSaleList[item], default: true };
                  return { label: typeOfSaleList[item] };
                })
                .map((asset, i) => (
                  <MenuItem default={asset.default} value={asset.label}>
                    {asset.label}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          {glassesAssetsSearchableList.includes(allValues?.assetTypeValue) ? null : (
            <React.Fragment>
              <Grid item md={3} sm={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="make"
                  label="Make"
                  disabled={searchVehicleLoader}
                  value={allValues?.make}
                  error={assetErrors?.make || assetErrors?.glassesMake}
                  helperText={assetErrors?.make || assetErrors?.glassesMake}
                  onChange={(event) => handle.onChangeField(event?.target?.value, "make")}
                  onBlur={(e) => handle.blurFn("make", e.target.value)}
                  variant="filled"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="model"
                  label="Model"
                  disabled={searchVehicleLoader}
                  value={allValues?.model}
                  error={assetErrors?.model}
                  helperText={assetErrors?.model}
                  onChange={(event) => handle.onChangeField(event?.target?.value, "model")}
                  onBlur={(e) => handle.blurFn("model", e.target.value)}
                  variant="filled"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Autocomplete
                  value={allValues?.condition || ""}
                  options={ConditionFieldOptions}
                  onChange={(event, newValue) => {
                    handle.onChangeField(newValue, "condition");
                    // handle.blurFn("condition", newValue);
                  }}
                  disabled={searchVehicleLoader || typeOfSale === "Private sale"}
                  size="small"
                  renderInput={(params) => <TextField size="small" {...params} label="Condition" variant="filled" />}
                />
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <Autocomplete
                  disabled={searchVehicleLoader}
                  value={allValues?.ageOfAssetValue?.toString() || ""}
                  options={generateArrayOfYears()}
                  onChange={(event, newValue) => {
                    handle.onChangeField(newValue, "ageOfAssetValue");
                    // handle.ageOfAsset(newValue);
                  }}
                  onKeyPress={(e) => checkAlphabetRestrict(e, "ageOfAssetValue")}
                  size="small"
                  getOptionLabel={(option) => option || ""}
                  renderInput={(params) => (
                    <TextField
                      // error={
                      //   allValues?.ageOfAssetValue
                      //     ? false
                      //     : fieldErrors?.ageOfAsset
                      // }
                      // helperText={
                      //   allValues?.ageOfAssetValue
                      //     ? false
                      //     : fieldErrors?.ageOfAsset
                      // }
                      size="small"
                      {...params}
                      label="Year of manufacture"
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        {glassesAssetsSearchableList.includes(allValues?.assetTypeValue) && (
          <React.Fragment>
            {showSearchAssetView && (
              <React.Fragment>
                {showSearchAssetView === "search" && !isManualAssets && (
                  <React.Fragment>
                    <Grid container spacing={1} direction="row" xs={12} style={{ margin: "0 0 20px" }}>
                      <Grid
                        item
                        // spacing={1}
                        // direction="row"
                        xs={12}
                        style={{ margin: "0 0 0px" }}
                      >
                        <Typography>Search for asset by Registration no. or VIN</Typography>
                      </Grid>
                      <Grid container item alignItems="center" spacing={2} xs={12}>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            id={"assetValue"}
                            fullWidth
                            variant="filled"
                            type="text"
                            name="assetValue"
                            label="VIN or Registration no."
                            size="small"
                            value={allValues?.searchQuery}
                            disabled={!userDetails?.is_service_enable || searchVehicleLoader}
                            // error={fieldErrors?.assetValue}
                            // helperText={fieldErrors?.assetValue}
                            placeholder="Type VIN or Registration"
                            onChange={(event) => handle.onChangeField(event?.target?.value, "searchQuery")}
                            onBlur={(e) => {
                              handle.onChangeField(
                                e.target.value,
                                e.target.value.length < 10 ? "registrationNumber" : "vin",
                              );

                              handle.updateAsset({
                                ...(e.target.value.length < 10
                                  ? { registrationNumber: e.target.value }
                                  : { vin: e.target.value }),
                              });
                            }}
                          />
                        </Grid>
                        {allValues.searchQuery.length < 10 && (
                          <Grid item md={3} sm={3} xs={12}>
                            <TextField
                              fullWidth
                              variant="filled"
                              style={{ background: "#ffffff" }}
                              select
                              disabled={!userDetails?.is_service_enable || searchVehicleLoader}
                              value={allValues?.registrationState}
                              name="registrationState"
                              label="Rego. state"
                              size="small"
                              onChange={(event) => {
                                handle.onChangeField(event?.target?.value, "registrationState");
                                handle.updateAsset({
                                  registrationState: event.target.value,
                                });
                              }}
                            >
                              {["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"].map((state, i) => (
                                <MenuItem value={state}>{state}</MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        )}
                        <Grid item md={3} sm={4} xs={12}>
                          <HtmlTooltip
                            tooltipPadding={"20px"}
                            title={
                              !userDetails?.is_service_enable && (
                                <React.Fragment>
                                  <Typography color="inherit" marginBottom="5px">
                                    {creditCardModalInfo.mainTitle}
                                  </Typography>
                                  <Typography color="inherit" fontSize="14px" marginBottom="10px">
                                    {creditCardModalInfo.subTitle}
                                  </Typography>
                                  <Link to="/billing/creditCard">
                                    <Button variant="outlined" size="small">
                                      {creditCardModalInfo.btnText}
                                    </Button>
                                  </Link>
                                </React.Fragment>
                              )
                            }
                          >
                            {/* <span>
                              <Button
                                style={{
                                  width: searchVehicleLoader ? "230px" : "auto",
                                }}
                                // size="small"
                                disabled={
                                  !userDetails?.is_service_enable ||
                                  searchVehicleLoader ||
                                  (!allValues?.vin && !allValues?.registrationNumber)
                                }
                                onClick={() =>
                                  handle.getLookupBlueflag({
                                    ...(allValues?.registrationNumber
                                      ? {
                                        registrationNumber: allValues?.registrationNumber,
                                        registrationState: allValues?.registrationState,
                                      }
                                      : { vin: allValues?.vin }),
                                  })
                                }
                                // size="small"
                                variant="outlined"
                              >
                                {searchVehicleLoader ? "Searching  for vehicle..." : "Search Vehicle"}
                              </Button>
                            </span> */}
                          </HtmlTooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} sm={6} mb="20px">
                      <HtmlTooltip
                        tooltipPadding={"20px"}
                        title={
                          !userDetails?.is_service_enable && (
                            <React.Fragment>
                              <Typography color="inherit" marginBottom="5px">
                                {creditCardModalInfo.mainTitle}
                              </Typography>
                              <Typography color="inherit" fontSize="14px" marginBottom="10px">
                                {creditCardModalInfo.subTitle}
                              </Typography>
                              <Link to="/billing/creditCard">
                                <Button variant="outlined" size="small">
                                  {creditCardModalInfo.btnText}
                                </Button>
                              </Link>
                            </React.Fragment>
                          )
                        }
                      >
                        <Card
                          style={{ height: "100%" }}
                          onClick={() => {
                            if (!userDetails?.is_service_enable || disableSearchVehicleButton) return;
                            handle.getLookupBlueflag({
                              ...(allValues?.registrationNumber
                                ? {
                                  registrationNumber: allValues?.registrationNumber,
                                  registrationState: allValues?.registrationState,
                                }
                                : { vin: allValues?.vin }),
                            });
                          }}
                        >
                          <CardActionArea
                            // disabled
                            disableRipple={disableSearchVehicleButton ? true : false}
                            style={{
                              height: "100%",
                              display: "flex",
                              alignContent: "start",
                              cursor: !userDetails?.is_service_enable || disableSearchVehicleButton ? "auto" : "",
                              opacity: !userDetails?.is_service_enable || disableSearchVehicleButton ? "0.4" : "",
                              backgroundColor:
                                !userDetails?.is_service_enable || disableSearchVehicleButton ? "#dfdfdf" : "",
                            }}
                          >
                            <CardContent>
                              <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img height="20px" src="/static/blueflag-logo.svg" alt="" />
                                <Typography fontWeight={600}>Search Vehicle</Typography>
                              </Stack>

                              <Typography fontSize={13}>Search for vehicle by VIN or registration number</Typography>
                            </CardContent>
                            {searchVehicleLoader && (
                              <Stack
                                spacing={2}
                                style={{
                                  background: "rgba(0,0,0, 0.5)",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: 0,
                                  right: 0,
                                  left: 0,
                                  bottom: 0,
                                  color: "#fff",
                                }}
                              >
                                <CircularProgress />
                                <Typography>Searching for vehicle...</Typography>
                              </Stack>
                            )}
                          </CardActionArea>
                        </Card>
                      </HtmlTooltip>
                      {searchVehicleLoader && (
                        <Typography variant="caption">{FILE_AWAITING_MESSAGE.gettingBlueflagFileMsg}</Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={6} mb="20px">
                      <HtmlTooltip
                        tooltipPadding={"20px"}
                        title={
                          !userDetails?.is_service_enable && (
                            <React.Fragment>
                              <Typography color="inherit" marginBottom="5px">
                                {creditCardModalInfo.mainTitle}
                              </Typography>
                              <Typography color="inherit" fontSize="14px" marginBottom="10px">
                                {creditCardModalInfo.subTitle}
                              </Typography>
                              <Link to="/billing/creditCard">
                                <Button variant="outlined" size="small">
                                  {creditCardModalInfo.btnText}
                                </Button>
                              </Link>
                            </React.Fragment>
                          )
                        }
                      >
                        <Card
                          style={{ height: "100%" }}
                          onClick={() => {
                            if (!userDetails?.is_service_enable || disablePPSRButton) return;
                            handle.getPPSRSearchBlueflag({
                              ...(allValues?.registrationNumber
                                ? {
                                  registrationNumber: allValues?.registrationNumber,
                                  registrationState: allValues?.registrationState,
                                }
                                : { vin: allValues?.vin }),
                            });
                          }}
                        >
                          <CardActionArea
                            // disabled
                            disableRipple={disablePPSRButton ? true : false}
                            style={{
                              height: "100%",
                              display: "flex",
                              alignContent: "start",
                              cursor: !userDetails?.is_service_enable || disablePPSRButton ? "auto" : "",
                              opacity: !userDetails?.is_service_enable || disablePPSRButton ? "0.4" : "",
                              backgroundColor:
                                !userDetails?.is_service_enable || disablePPSRButton ? "#dfdfdf" : "",
                            }}
                          >
                            <CardContent>
                              <Stack
                                direction="row"
                                spacing={2}
                                style={{
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img height="20px" src="/static/blueflag-logo.svg" alt="" />
                                <Typography fontWeight={600}>Get PPSR</Typography>
                              </Stack>

                              <Typography fontSize={13}>Search PPSR by VIN or registration number</Typography>
                            </CardContent>
                            {searchPPSRLoader && (
                              <Stack
                                spacing={2}
                                style={{
                                  background: "rgba(0,0,0, 0.5)",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: 0,
                                  right: 0,
                                  left: 0,
                                  bottom: 0,
                                  color: "#fff",
                                }}
                              >
                                <CircularProgress />
                                <Typography>Getting Blueflag data...</Typography>
                              </Stack>
                            )}
                          </CardActionArea>
                        </Card>
                      </HtmlTooltip>
                      {asset?.documents?.filter((doc) => doc.name === "PPSR File")?.length <= 0 &&
                        searchPPSRLoader && (
                          <Typography variant="caption">{FILE_AWAITING_MESSAGE.gettingBlueflagFileMsg}</Typography>
                        )}
                      <Typography variant="caption">
                        {disablePPSRButton && `${!searchPPSRLoader && blueflagRequirementMessage}`}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}

                {!isManualAssets && (
                  <Grid container spacing={1} direction="row" style={{ margin: "0 0 0px" }}>
                    <Grid
                      item
                      // spacing={1}
                      direction="row"
                      style={{ margin: "0 0 0px" }}
                    >
                      <Typography>Search asset via Glass's Guide</Typography>
                    </Grid>

                    <Grid container spacing={1} direction="row" style={{ margin: "0 0 0px" }}></Grid>
                  </Grid>
                )}

                <Grid container spacing={1} direction="row" style={{ margin: "0 0 0px" }}>
                  {!isManualAssets ? (
                    <React.Fragment>
                      <Grid item md={2} sm={12}>
                        <Autocomplete
                          value={allValues?.condition || ""}
                          options={ConditionFieldOptions}
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "condition");
                            // handle.blurFn("condition", newValue);
                          }}
                          disabled={searchVehicleLoader || typeOfSale === "Private sale"}
                          size="small"
                          renderInput={(params) => (
                            <TextField size="small" {...params} label="Condition" variant="filled" />
                          )}
                        />
                      </Grid>
                      <Grid item md={2} sm={12}>
                        <Autocomplete
                          value={allValues?.ageOfAssetValue?.toString() || ""}
                          options={generateArrayOfYears()}
                          disabled={searchVehicleLoader || !allValues?.assetTypeValue}
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "ageOfAssetValue");
                            // handle.ageOfAsset(newValue);
                            if (newValue && newValue !== null) {
                              dispatch(
                                getListOfMakes({
                                  assetTypeCode: allValuesRef?.current?.assetModelTypeCode || "APAS",
                                  year: newValue,
                                }),
                              );
                            }
                            // else {
                            //   handle.cleanAssetList("ageOfAssetValue");
                            // }
                          }}
                          onKeyPress={(e) => checkAlphabetRestrict(e, "ageOfAssetValue")}
                          size="small"
                          getOptionLabel={(option) => option || ""}
                          renderInput={(params) => (
                            <TextField
                              error={allValues?.ageOfAssetValue ? false : assetErrors?.ageOfAsset}
                              helperText={allValues?.ageOfAssetValue ? false : assetErrors?.ageOfAsset}
                              size="small"
                              {...params}
                              label="Year"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={2.5} sm={3}>
                        <Autocomplete
                          // defaultValue={allValues?.glassesMake?.label || ""}
                          loading={makeLoader}
                          loadingText={<AutoCompleteLoader />}
                          value={allValues?.glassesMake || ""}
                          options={
                            (listOfMakes?.length > 0 &&
                              listOfMakes?.map((make) => ({ label: make?.name, value: make?.code }))) ||
                            []
                          }
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "glassesMake"); // clear fields if we update value of this
                            // handle.blurFn("glassesMake", newValue);
                            if (newValue?.value) {
                              handle.getListOfModels(newValue?.value);
                            }
                            // else {
                            //   handle.cleanAssetList("glassesMake");
                            // }
                          }}
                          disabled={
                            searchVehicleLoader || !listOfMakes?.[0]
                            // && !allValues?.ageOfAssetValue
                          }
                          // onKeyPress={(e) => checkAlphabetRestrict(e, "make")}
                          size="small"
                          getOptionLabel={(option) => option.label || ""}
                          renderInput={(params) => (
                            <TextField
                              error={!listOfMakes?.[0] || allValues?.glassesMake ? false : assetErrors?.make}
                              helperText={!listOfMakes?.[0] || allValues?.glassesMake ? false : assetErrors?.make}
                              size="small"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                value: params.inputProps.value === "undefined" ? "" : params.inputProps.value,
                              }}
                              label="Make"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={2.5} sm={3}>
                        <Autocomplete
                          // defaultValue={allValues?.glassesMake?.label || ""}
                          loading={modelLoader}
                          loadingText={<AutoCompleteLoader />}
                          disabled={searchVehicleLoader || (!listOfModels?.[0] && !allValues?.glassesMake?.label)}
                          value={allValues?.glassesModel || ""}
                          options={
                            (listOfModels?.length > 0 &&
                              listOfModels?.map((model) => ({
                                label: model?.Name || null,
                                value: model?.Code || null,
                              }))) ||
                            []
                          }
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "glassesModel");
                            // handle.blurFn("glassesModel", newValue);
                            if (newValue?.value) {
                              handle.getListOfVariants(newValue?.value);
                            }
                            // else {
                            //   handle.cleanAssetList("glassesModel");
                            // }
                          }}
                          // onKeyPress={(e) => checkAlphabetRestrict(e, "make")}
                          size="small"
                          getOptionLabel={(option) => option?.label || ""}
                          renderInput={(params) => (
                            <TextField
                              error={
                                (!listOfModels?.[0] && !allValues?.glassesMake?.label) || allValues?.glassesModel
                                  ? false
                                  : assetErrors?.model
                              }
                              helperText={
                                (!listOfModels?.[0] && !allValues?.glassesMake?.label) || allValues?.glassesModel
                                  ? false
                                  : assetErrors?.model
                              }
                              size="small"
                              {...params}
                              label="Model"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3} sm={3}>
                        <Autocomplete
                          loading={variantLoader}
                          loadingText={<AutoCompleteLoader />}
                          disabled={searchVehicleLoader || (!listOfVariants?.[0] && !allValues?.glassesModel?.label)}
                          // defaultValue={allValues?.glassesMake?.label || ""}
                          value={allValues?.glassesVariant || ""}
                          options={
                            (listOfVariants?.length > 0 &&
                              listOfVariants?.map((variant) => ({
                                label: variant?.Name,
                                value: variant?.Name,
                              }))) ||
                            []
                          }
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "glassesVariant");
                            // handle.blurFn("glassesVariant", newValue);

                            if (newValue?.value) {
                              handle.getNVIC(newValue?.value);
                            }
                            // else {
                            //   handle.cleanAssetList("glassesVariant");
                            // }
                          }}
                          // onKeyPress={(e) => checkAlphabetRestrict(e, "make")}
                          size="small"
                          getOptionLabel={(option) => option?.label || ""}
                          renderInput={(params) => (
                            <TextField
                              error={
                                (!listOfVariants?.[0] && !allValues?.glassesModel?.label) ||
                                  allValues?.glassesVariant?.label
                                  ? false
                                  : assetErrors?.variant
                              }
                              helperText={
                                (!listOfVariants?.[0] && !allValues?.glassesModel?.label) ||
                                  allValues?.glassesVariant?.label
                                  ? false
                                  : assetErrors?.variant
                              }
                              size="small"
                              {...params}
                              // inputProps={{
                              //   ...params.inputProps,
                              //   value:
                              //     params.inputProps.value === "undefined"
                              //       ? ""
                              //       : params.inputProps.value,
                              // }}
                              label="Variant"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item md={3} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          name="make"
                          label="Make"
                          disabled={searchVehicleLoader}
                          value={allValues?.make}
                          error={assetErrors?.make || assetErrors?.glassesMake}
                          helperText={assetErrors?.make || assetErrors?.glassesMake}
                          onChange={(event) => handle.onChangeField(event?.target?.value, "make")}
                          onBlur={(e) => handle.blurFn("make", e.target.value)}
                          variant="filled"
                          size="small"
                          inputProps={{ maxLength: 7 }}
                        />
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          type="text"
                          name="model"
                          label="Model"
                          disabled={searchVehicleLoader}
                          value={allValues?.model}
                          error={assetErrors?.model}
                          helperText={assetErrors?.model}
                          onChange={(event) => handle.onChangeField(event?.target?.value, "model")}
                          onBlur={(e) => handle.blurFn("model", e.target.value)}
                          variant="filled"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <Autocomplete
                          value={allValues?.condition || ""}
                          options={ConditionFieldOptions}
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "condition");
                            // handle.blurFn("condition", newValue);
                          }}
                          disabled={searchVehicleLoader || typeOfSale === "Private sale"}
                          size="small"
                          renderInput={(params) => (
                            <TextField size="small" {...params} label="Condition" variant="filled" />
                          )}
                        />
                      </Grid>
                      <Grid item md={3} sm={12} xs={12}>
                        <Autocomplete
                          disabled={searchVehicleLoader}
                          value={allValues?.ageOfAssetValue?.toString() || ""}
                          options={generateArrayOfYears()}
                          onChange={(event, newValue) => {
                            handle.onChangeField(newValue, "ageOfAssetValue");
                            // handle.ageOfAsset(newValue);
                          }}
                          onKeyPress={(e) => checkAlphabetRestrict(e, "ageOfAssetValue")}
                          size="small"
                          getOptionLabel={(option) => option || ""}
                          renderInput={(params) => (
                            <TextField
                              // error={
                              //   allValues?.ageOfAssetValue
                              //     ? false
                              //     : fieldErrors?.ageOfAsset
                              // }
                              // helperText={
                              //   allValues?.ageOfAssetValue
                              //     ? false
                              //     : fieldErrors?.ageOfAsset
                              // }
                              size="small"
                              {...params}
                              label="Year of manufacture"
                              variant="filled"
                            />
                          )}
                        />
                      </Grid>
                    </React.Fragment>
                  )}

                  {/* <Grid item md={3} sm={3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      type="text"
                      name="model"
                      label="Series"
                      value={allValues?.model}
                      error={fieldErrors?.model}
                      helperText={fieldErrors?.model}
                      onChange={(event) =>
                        handle.onChangeField(event?.target?.value, "model")
                      }
                      onBlur={(e) => handle.blurFn("model", e.target.value)}
                      variant="filled"
                      size="small"
                    />
                    </Grid> */}
                  {/* </Grid> */}
                </Grid>
                {!isManualAssets && (
                  <Grid container spacing={1} direction="row" style={{ margin: "0 0 0px" }}>
                    <Grid item md={12} sm={11}>
                      <Autocomplete
                        loading={NVICLoader}
                        loadingText={<AutoCompleteLoader />}
                        disabled={searchVehicleLoader || (!NVICList?.[0] && !allValues?.glassesVariant?.label)}
                        // defaultValue={allValues?.glassesMake?.label || ""}
                        value={allValues?.glassesNVIC || ""}
                        options={
                          (NVICList?.length > 0 &&
                            NVICList?.map((model) => ({
                              label: model?.ModelName,
                              NVIC: model?.NVIC_CUR,
                              NVIC_MODEL: model?.NVIC_MODEL,
                            }))) ||
                          []
                        }
                        onChange={(event, newValue) => {
                          handle.onChangeField(newValue, "glassesNVIC");
                          handle.blurFn("glassesNVIC", newValue);
                          // console.log("newValue", newValue);
                          if (newValue?.NVIC) {
                            handle.getDetailsSpecificationAll(newValue?.NVIC);
                          }
                        }}
                        // onKeyPress={(e) => checkAlphabetRestrict(e, "make")}
                        size="small"
                        // getOptionLabel={(option) => option?.label}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              value: params.inputProps.value === "" ? "" : params.inputProps.value,
                            }}
                            label="Full model name"
                            variant="filled"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {glassesAssetsSearchableList.includes(allValues?.assetTypeValue) && (
          // <Grid container sx={{ ml: 0 }} style={{ margin: "0 0 0 0" }}>
          <Grid container sx={{ mt: 1, ml: 1 }}>
            <Button variant="outlined" onClick={handleToggleAssetDetails}>
              {!isManualAssets ? "ADD ASSET DETAILS MANUALLY" : "USE GLASSES GUIDE"}
            </Button>
          </Grid>
        )}
        {asset?.blueflagPPSR?.search?.length > 0 && (
          <Grid item xs={12} mb={2}>
            <Typography
              fontWeight={600}
              style={{
                marginBottom: "10px",
              }}
            >
              PPSR
            </Typography>

            <Grid container item direction="row" mt="10px">
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Type
                </Typography>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Security group
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Proceeds Claimed
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  Start date
                </Typography>
              </Grid>
              <Grid item sm={1} xs={12}>
                <Typography variant="subtitle2" fontWeight={600} style={{ margin: "0 0 5px" }}>
                  End date
                </Typography>
              </Grid>
            </Grid>
            {asset?.blueflagPPSR?.search.map((searchResult) => (
              <Grid container item direction="row">
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 2px" }}>
                    {searchResult.registration_detail.collateral_class_description}
                  </Typography>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 2px" }}>
                    {searchResult.registration_detail.secured_parties.map((group) => (
                      <Stack>{group.organisation.organisation_name}</Stack>
                    ))}
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 2px" }}>
                    {searchResult.registration_detail.are_proceeds_claimed}
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 2px" }}>
                    {moment(searchResult.registration_detail.registration_start_time).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid item sm={1} xs={12}>
                  <Typography variant="body2" style={{ margin: "0 0 2px" }}>
                    {moment(searchResult.registration_detail.registration_end_time).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container direction="row" mt="10px">
          <Stack
            spacing={1}
            direction="row"
            style={{ margin: "0 0 10px" }}
          // divider={<Divider orientation="vertical" flexItem />}
          >
            {asset?.documents?.length > 0 &&
              asset?.documents
                ?.filter((doc) => doc.name === "PPSR Certificate")
                .map((doc) => <Document document={doc} name="ppsr" />)}
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Asset;
