import * as React from "react";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import parse from 'autosuggest-highlight/parse';
import throttle from "lodash/throttle";
import * as Yup from "yup";
import { debounce } from "lodash";
import regex from "../../utils/regex";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
let placesService = null;
let map = null;

// function withGoogleMapScript(WrappedComponent) {
//   return function (props) {
//     const [isLoaded, setIsLoaded] = React.useState(false);
//     React.useEffect(() => {
//       const script = document.createElement("script");
//       script.src =
//         "https://maps.googleapis.com/maps/api/js?key=AIzaSyByJ8mHpDMsQYWH-ToZE5eLBvEouildEYs&libraries=places";
//       script.async = true;
//       script.setAttribute("id", "google-maps");
//       document.querySelector("head").append(script);
//       script.onload = () => {
//         setIsLoaded(true);
//       };
//     }, []);

//     return <>{isLoaded ? <WrappedComponent {...props} /> : null}</>;
//   };
// }

function GoogleMaps({
  handleCreateAddress,
  handleRemoveAddress,
  handleUpdateAddress,
  guarantorFieldErrors,
  setGuarantorFieldErrors,
  singleCustomerId,
  address,
  isEmptyAddress,
  isEntity,
  customerId,
  size,
  style,
  index,
  addressFieldName,
  validateComponentField,
  canRemoveAddress = true,
}) {
  const [value, setValue] = useState(address?.fullAddress || address || "");
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyByJ8mHpDMsQYWH-ToZE5eLBvEouildEYs&libraries=places",
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    if (window.google) {
      map = new window.google.maps.Map(document.querySelector("#google-maps"), {
        center: new window.google.maps.LatLng(0, 0),
        zoom: 15,
      });
    }
  }, [window.google]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    if (inputValue?.length <= 200) {
      let active = true;

      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }

      if (!placesService && map) {
        placesService = new window.google.maps.places.PlacesService(map);
      }

      if (!placesService) {
        return undefined;
      }

      if (!autocompleteService.current) {
        return undefined;
      }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch({ input: inputValue }, (results) => {
        // console.log(results);
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }
  }, [value, inputValue, fetch]);

  const [fieldErrors, setFieldErrors] = useState({
    fullAddress: "",
  });

  const validationSchema = Yup.object({
    fullAddress: Yup.string()
      .required("address field is required")
      // .matches(regex.addressRegex, "Please enter valid address")
      .min(3, "Minimum of 3 characters")
      .max(200, "Maximum of 200 characters"),
    entityAddress: Yup.string().optional()
  });

  // handle google address using debounce
  // const validateField = async (fieldName, value) => {
  //   try {
  //     if (validateComponentField !== undefined) {
  //       await validateComponentField(fieldName, value);
  //     }
  //     const name = fieldName.split("_")[0];
  //     if (isEntity) {
  //       setGuarantorFieldErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [fieldName]: "",
  //       }));
  //     } else {
  //       setGuarantorFieldErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [fieldName]: "",
  //       }));
  //     }
  //     await validationSchema.validateAt(name, { [name]: value });
  //     setFieldErrors((prevErrors) => ({ ...prevErrors, fullAddress: "" }));
  //   } catch (error) {
  //     if (isEntity) {
  //       setGuarantorFieldErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [fieldName]: error.message,
  //       }));
  //     } else {
  //       setGuarantorFieldErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [fieldName]: error.message,
  //       }));
  //     }
  //     setFieldErrors((prevErrors) => ({
  //       ...prevErrors,
  //       fullAddress: error.message,
  //     }));
  //   }
  // };

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        if (validateComponentField !== undefined) {
          await validateComponentField(fieldName, value);
        }
        const name = fieldName.split("_")[0];
        if (isEntity) {
          setGuarantorFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "",
          }));
        } else {
          setGuarantorFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: "",
          }));
        }
        await validationSchema.validateAt(name, { [name]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, fullAddress: "" }));
      } catch (error) {
        if (isEntity) {
          setGuarantorFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error.message,
          }));
        } else {
          setGuarantorFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error.message,
          }));
        }
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          fullAddress: error.message,
        }));
      }
    }, 300),
    []
  );

  const onChangeFn = (name, newInputValue) => {
    let isValid = true;
    // const nameField = name.split("_")[0];
    isValid = regex.addressRegex.test(newInputValue) && newInputValue?.length <= 200;

    if (!isValid) {
      // await validateField(name, newInputValue);
      debouncedValidation(name, newInputValue)
      return;
    }

    setInputValue(newInputValue);
    setFieldErrors({ fullAddress: "" });
  };

  function getErrorCondition(value, isEntity, singleCustomerId, customerId, guarantorFieldErrors, fieldErrors, field) {
    if (isEntity) {
      return guarantorFieldErrors[field];
    } else {
      return guarantorFieldErrors[field];
    }

    return false;
  }

  const addressValue = (value?._id && !value?.fullAddress) ? "" : value

  return (
    <Autocomplete
      id="google-map-demo"
      disableClearable={!canRemoveAddress}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      blurOnSelect={true}
      autoComplete
      includeInputInList
      filterSelectedOptions
      defaultValue={isEmptyAddress ? "" : value}
      // value={isEmptyAddress ? "" : value}
      value={isEmptyAddress ? "" : addressValue}
      style={style}
      onChange={async (event, newValue) => {
        if ((newValue === null || !newValue) && canRemoveAddress) {
          handleRemoveAddress(address);
          return;
        }

        if (newValue) {
          placesService.getDetails(
            {
              placeId: newValue?.place_id,
              // fields: ['formatted_address', 'adr_address'],
            },
            (place) => {
              // console.log({ place });
              // if (address && place) {
              //   setOptions(newValue ? [newValue, ...options] : options);
              //   setValue(newValue);
              //   handleCreateAddress({
              //     _id: address?._id,
              //     address: place,
              //   });
              //   setFieldErrors({ address: "" });
              //   return;
              // }
              if (address) {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                isEntity
                  ? handleUpdateAddress({
                      _id: address?._id,
                      address: place,
                      entityId: customerId,
                    })
                  : handleUpdateAddress({
                      _id: address?._id,
                      address: place,
                      customerId: customerId,
                    });
                setFieldErrors({ fullAddress: "" });
                return;
              }

              if (place) {
                console.log("place", place);
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handleCreateAddress(place);
                setFieldErrors({ fullAddress: "" });
              }
            },
          );
        } else {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          setFieldErrors({ fullAddress: "" });
        }
      }}
      onInputChange={(event, newInputValue) => {
        onChangeFn(addressFieldName, newInputValue);
        // console.log(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Address"
          fullWidth
          variant="filled"
          size="small"
          name={addressFieldName}
          error={getErrorCondition(
            value,
            isEntity,
            singleCustomerId,
            customerId,
            guarantorFieldErrors,
            fieldErrors,
            addressFieldName,
          )}
          helperText={getErrorCondition(
            value,
            isEntity,
            singleCustomerId,
            customerId,
            guarantorFieldErrors,
            fieldErrors,
            addressFieldName,
          )}
          // value
          // ? false
          // : isEntity
          //   ? singleCustomerId === customerId && guarantorFieldErrors?.entityAddress
          //   : (singleCustomerId === customerId && guarantorFieldErrors?.address) || fieldErrors?.address

          onChange={(event) => {
            // await validateField(addressFieldName, event?.target?.value);
            debouncedValidation(addressFieldName, event?.target?.value)
            try {
              if (fieldErrors["fullAddress"] === "") {
                return;
              }
            } catch (error) {
              const newErrors = {};
              error.inner.forEach((validationError) => {
                newErrors[validationError.path] = validationError.message;
              });
              setFieldErrors(newErrors);
            }
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid item>
              <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="text.secondary">
                {option.description}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}

export default GoogleMaps;

// export default withGoogleMapScript(GoogleMaps);
