import React, { useState } from "react";
import { useDispatch } from "react-redux";
import HtmlTwoToneIcon from "@mui/icons-material/HtmlTwoTone";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfTwoTone";
import DescriptionIcon from '@mui/icons-material/Description';
import { Card, CardActionArea, IconButton, Link, Paper, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { ERRORS_MESSAGE } from "../../../src/constants";
import { deleteDocument } from "../../../src/store/slices/applicationFormSlice";
import ConfirmModal from "../customComponents/ConfirmModal";

const ext = [".jpg", ".jpeg", ".png", ".gif"];

const nameOfDocUploadComponent = [
  "privacyAgreement",
  // "identity",
  // "creditProfile",
  // "financialStatement",
  "otherDocuments",
];

export default function Document({ document, name }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  if (nameOfDocUploadComponent.includes(name) && !document.url) {
    return null;
  }

  const handle = {
    onDelete: (data) => {
      if (data?.isSave) {
        const payloadData = {
          ...(document?.customer && { customerId: document.customer }),
          ...(document?.entity && { entityId: document.entity }),
          ...(document?.asset && { assetId: document.asset }),
          documentId: document._id,
          applicationId: document.application
        }
        dispatch(deleteDocument(payloadData)).then((res) => {
          if (res.error) {
            enqueueSnackbar(res?.payload?.response_error || ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        })
      }
      setIsConfirmModalOpen(false)
    }
  }

  return (
    <React.Fragment>
      <Link
        href={document.url}
        target="_blank"
        size="small"
        color="secondary"
        style={{ textDecoration: "none" }}
      >
        <Stack direction="column" style={{ alignItems: "center", position: "relative" }}>
          <Card
            elevation={1}
            item
            xs={3}
            style={{
              height: "100px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              // background: "transparent",
              padding: 5,
              objectFit: "cover",
              position: "relative",
            }}
          >
            <CardActionArea>
              <ImageComponent doc={document} />
            </CardActionArea>
            {/* Delete Icon */}
            <IconButton
              aria-label="delete"
              size="small"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "#d32f2f",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsConfirmModalOpen(true)
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Card>
          <Typography variant="subtitle2" textAlign="center">
            {document?.name}
          </Typography>
        </Stack>
      </Link>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handle={handle.onDelete}
        setIsOpen={setIsConfirmModalOpen}
        title={"Remove document"}
        btnTextYes="Remove"
        message={"Are you sure you want to remove document?"}
      />
    </React.Fragment>
  );
}

const ImageComponent = ({ width, doc }) => {

  const getDocIcon = (docExtension) => {
    switch (docExtension) {
      case ".html":
        return <HtmlTwoToneIcon fontSize="large" />
      case ".csv":
        return <DescriptionIcon />
      case ".pdf":
        return <PictureAsPdfTwoToneIcon />

      default:
        return <PictureAsPdfTwoToneIcon />
    }
  }

  if (doc?.ext === ".webm") {
    return (
      <video
        src={doc?.url}
        style={{
          minWidth: "76px",
          maxWidth: "76px",
        }}
      ></video>
    );
  }

  if (ext.includes(doc?.ext))
    return (
      <Paper
        elevation={0}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "5px",
          background: `url(${doc?.url})`,
          backgroundPosition: "center center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          padding: "5px",
          minHeight: "86px",
          minWidth: "76px",
          maxWidth: "76px",
        }}
      ></Paper>
    );

  return (
    <Paper
      elevation={0}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5px",
        background: "transparent",
        padding: "30px 25px",
        minWidth: "76px",
        maxWidth: "76px",
      }}
    >
      {getDocIcon(doc?.ext)}
    </Paper>
  );
};
