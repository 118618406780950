import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button, TextField, Card, CardContent, Stack, Box } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { DeletePaymentCredit, addPaymentCredit, getPaymentMethod, userSelector } from "../../../store/slices/userSlice";
import ConfirmModal from "../../../components/customComponents/ConfirmModal";
import regex from "../../../utils/regex";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: 500,
      border: "1px solid black",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "black" },
      "::placeholder": { color: "black" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "black",
    },
  },
};

const CreditCard = () => {
  const dispatch = useDispatch();
  const { getAllPayments, firstName, lastName } = useSelector(userSelector);
  const stripe = useStripe();
  const elements = useElements();
  const [customError, setCustomError] = useState({
    cardNumberError: "",
    expirationYearError: "",
    cvvError: "",
  });
  const [allValue, setAllValue] = useState({ cardName: "" });
  const [fieldErrors, setFieldErrors] = useState({
    cardName: "",
  });

  const [isRemoveCard, setIsRemoveCard] = useState(false);

  const getAllPaymentMethod = () => {
    dispatch(getPaymentMethod());
    return;
  };

  useEffect(() => {
    getAllPaymentMethod();
  }, []);

  const validationSchema = Yup.object({
    cardName: Yup.string().required().matches(regex.name, "Only string allowed"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onSubmit: async (e) => {
      await validateField("cardName", allValue?.cardName);
      e.preventDefault();
      if (allValue?.cardName === "") return;
      const paymentMethodObj = {
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: allValue?.cardName,
        },
      };

      const { error, paymentMethod } = await stripe.createPaymentMethod(paymentMethodObj);

      if (!error) {
        try {
          const { id } = paymentMethod;
          dispatch(addPaymentCredit({ payment_method_id: id })).then((res) => {
            if (res?.payload?.status_code === 200) {
              enqueueSnackbar(res?.payload?.status_message, {
                variant: "success",
              });
              getAllPaymentMethod();
            } else {
              // set api call error message
            }
          });
        } catch (error) {
          console.log("Error", error);
        }
      } else {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
    },
    cardNumberChange: (change, name) => {
      if (name === "cardNo") {
        setCustomError({
          cardNumberError: change,
          expirationYearError: customError?.expirationYearError,
          cvvError: customError?.cvvError,
        });
      } else if (name === "expirationYear") {
        setCustomError({
          cardNumberError: customError?.cardNumberError,
          expirationYearError: change,
          cvvError: customError?.cvvError,
        });
      } else {
        setCustomError({
          cardNumberError: customError?.cardNumberError,
          expirationYearError: customError?.expirationYearError,
          cvvError: change,
        });
      }
    },
    onChange: async (value, name) => {
      await validateField(name, value);
      setAllValue({ ...allValue, [name]: value });
    },
    removeCreditCard: (data) => {
      if (data?.isSave) {
        dispatch(DeletePaymentCredit({})).then(({ payload }) => {
          if (payload?.status_code === 200) {
            enqueueSnackbar(payload?.status_message, {
              variant: "success",
            });
            setAllValue({ cardName: "" });
            getAllPaymentMethod();
          }
        });
      }
    }
  };

  return (
    <Grid container spacing={1.5} direction={"column"}>
      {!getAllPayments?.id ? (
        <Grid item sx={8}>
          <Card>
            <CardContent>
              <form onSubmit={handle.onSubmit}>
                <Typography variant="subtitle2">Name on Card</Typography>
                <TextField
                  label="Name on Card"
                  variant="filled"
                  type="text"
                  value={allValue?.cardName}
                  error={fieldErrors?.cardName}
                  helperText={fieldErrors?.cardName}
                  onChange={(event) => handle.onChange(event.target.value, "cardName")}
                  size="small"
                  sx={{ marginBottom: "10px" }}
                  fullWidth
                />
                <Typography variant="subtitle2">Card Detail</Typography>
                <Box
                  sx={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                    padding: "15px",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                  }}
                >
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={(e) => handle.cardNumberChange(e, "card")}
                  />
                </Box>
                <Button type="submit" sx={{ marginTop: "20px" }} variant="outlined">
                  Save
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Stack sx={{ flexDirection: "row" }}>
          <Grid item xs={12}>
            <Stack
              key={getAllPayments?._id}
              sx={{
                flexDirection: "row",
                border: "1px solid black",
                borderRadius: "15px",
                padding: "15px",
                justifyContent: "space-between",
              }}
            >
              <Stack>
                <Typography variant="subtitle1">Card Detail</Typography>
                <Typography variant="subtitle2">xxxx xxxx xxxx {getAllPayments?.card?.last4}</Typography>
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Name on the Card</Typography>
                <Typography variant="subtitle2">
                  {getAllPayments?.billing_details?.name || `${firstName} ${lastName}`}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sx={{ alignItems: "center", display: "flex" }}>
            <Stack
              sx={{
                cursor: "pointer",
                alignContent: "center",
                marginLeft: "5px",
              }}
            >
              <DeleteOutlineIcon onClick={() => setIsRemoveCard(!isRemoveCard)} />
            </Stack>
          </Grid>
        </Stack>
      )}

      <ConfirmModal
        isOpen={isRemoveCard}
        handle={handle.removeCreditCard}
        setIsOpen={setIsRemoveCard}
        title={"Remove credit card"}
        message={"Are you sure you want to remove your credit card?"}
      />
    </Grid>
  );
};

export default CreditCard;
